<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <!-- <el-form-item label="类型：" prop="type">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                v-for="(item, index) in categoryNoAllOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="父ID：" prop="pid">
            <el-select v-model="form.pid" placeholder="请选择">
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
                <span v-html="item.optionName"></span>
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="门店：" prop="shopId">
            <el-select clearable filterable v-model="form.shopId" placeholder="请选择">
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="标志：" prop="flag">
            <el-select v-model="form.flag" placeholder="请选择" multiple>
              <el-option
                v-for="(item, index) in CATEGORY_TAG_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="关键字：" prop="keywords">
            <el-input v-model="form.keywords"></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description">
            <el-input v-model="form.description" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="权重：" prop="weigh">
            <el-input v-model="form.weigh" type="number"></el-input>
          </el-form-item>
          
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio
                v-for="(item, index) in USER_STATUS_OPTIONS"
                :key="index"
                :label="item.value"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  USER_STATUS_OPTIONS,
  CATEGORY_OPTIONS,
  CATEGORY_TAG_OPTIONS,
} from "@/helpers/constants";
import {
  listAll,
  save,
  updateById,
} from "@/apis/unidrink/category";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
// import { reactive } from "vue";
// const categoryNoAllOptions = reactive(
//   CATEGORY_OPTIONS.filter((item) => item.value != "all")
// );
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      USER_STATUS_OPTIONS: USER_STATUS_OPTIONS,
      CATEGORY_TAG_OPTIONS: CATEGORY_TAG_OPTIONS,
      categoryNoAllOptions: CATEGORY_OPTIONS.filter(
        (item) => item.value != "all"
      ),
      initForm: {
        status: "normal",
        // type: "product",
        pid: 0,
        weigh: 0
      },
      form: {},
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        shopId: [{ required: true, message: "门店不能为空", trigger: "blur" }],
        weigh: [{ required: true, message: "权重不能为空", trigger: "blur" }],
      },
      shopOptions: [],
      categoryOptions: [],
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
    this.getShopOptions();
  },
  watch: {
    modelValue(val) {
      if (val) {
        // this.getCategoryOptions();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit" || this.mode === "copy") {
        this.form = cloneDeep(this.editData);
        this.form.flag = this.form.flag ? this.form.flag.split(",") : [];
        console.log(this.form.name.split("&nbsp;"));
        this.form.shopId = Number(this.form.shopId);

        this.form.name = this.form.name
          .split("&nbsp;")
          .join("")
          .replace(/[└]/g, "")
          .trim();
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        console.log(this.form);
        let form = cloneDeep(this.form);
        form.flag = form.flag.join(",");
        const promise = this.mode === "edit" ? updateById : save;
        promise(form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },

    getShopOptions() {
      const page = 1;
      const limit = 10;
      this.shopOptions = [];
      const fn = (page, limit) => {
        getShopList(page, limit).then(({ rows, total }) => {
          this.shopOptions.push(...rows);
          if (page * limit < total) {
            fn(page + 1, limit);
          }
        });
      };
      fn(page, limit);
    },

    getCategoryOptions() {
      this.categoryOptions = [];
      listAll("all").then(({ rows, total }) => {
        this.categoryOptions.push(
          ...rows.map((row) => {
            console.log(row);
            return {
              ...row,
              name: row.name
                .split("&nbsp;")
                .join("")
                // .replace(/[└]/g, "")
                .trim(),
              optionName: row.name,
            };
          })
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
